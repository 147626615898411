import styled from '@emotion/styled';
import { Children, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
// These css imports are necessary for the slider to work and the recommended way from the documentation
// Read more here: https://www.npmjs.com/package/react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselStepper from './CarouselStepper';

const SliderContainer = styled.div`
  display: contents;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  .slick-track {
    display: flex;
  }

  .slick-slide div:nth-child(1) {
    align-self: stretch;
    width: fill-available;
    display: flex;
  }

  .slick-slide {
    height: unset;
    display: flex;
  }
`;

type CarouselProps = {
  children: ReactNode;
  differentNrOfSlidesPerBreakpoint?: boolean;
  maxNrOfSlides?: number;
  swipe?: boolean;
  hideStepper?: boolean;
};

export default function Carousel({
  children,
  differentNrOfSlidesPerBreakpoint = false,
  maxNrOfSlides = 3,
  swipe = false,
  hideStepper = false,
}: CarouselProps) {
  const [windowWidth, setWindowWidth] = useState(0);
  const [nrOfSlidesPerPage, setNrOfSlidesPerPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const nrOfChildren = Children.count(children);

  const onSlideChange = useCallback(
    (index: number) => {
      setCurrentPage(index / nrOfSlidesPerPage + 1);
    },
    [nrOfSlidesPerPage],
  );

  const settings = {
    dots: false,
    arrows: false,
    infinite: differentNrOfSlidesPerBreakpoint && nrOfChildren > 1,
    speed: 500,
    slidesToShow: nrOfChildren < nrOfSlidesPerPage ? nrOfChildren : nrOfSlidesPerPage,
    slidesToScroll: nrOfSlidesPerPage,
    swipe,
    afterChange: onSlideChange,
  };

  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const updateSlidesToShow = () => {
      let nrOfSlidesPerPage = differentNrOfSlidesPerBreakpoint ? maxNrOfSlides : 1;

      if (differentNrOfSlidesPerBreakpoint) {
        if (windowWidth <= 570) {
          nrOfSlidesPerPage = 1;
        } else if (windowWidth <= 1022) {
          nrOfSlidesPerPage = 2;
        } else if (windowWidth <= 1025) {
          nrOfSlidesPerPage = 3;
        } else if (windowWidth <= 1160) {
          nrOfSlidesPerPage = 2;
        }
      }

      setNrOfSlidesPerPage(nrOfSlidesPerPage);
      setPageCount(Math.ceil(Children.count(children) / nrOfSlidesPerPage));
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    updateSlidesToShow();

    return () => window.removeEventListener('resize', handleResize);
  }, [children, differentNrOfSlidesPerBreakpoint, windowWidth, maxNrOfSlides]);

  return (
    <SliderContainer>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      {nrOfChildren > 1 && pageCount !== 1 && !swipe && !hideStepper && (
        <CarouselStepper sliderRef={sliderRef} pageCount={pageCount} currentPage={currentPage} />
      )}
    </SliderContainer>
  );
}
