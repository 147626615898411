import styled from '@emotion/styled';
import Typography from '../../text/Typography';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import Container from '../Container';
import Carousel from '../../carousel/Carousel';
import SectionTitle from '../../SectionTitle';
import SecondaryProfileCard from './SecondaryProfileCard';
import PrimaryProfileCard from './PrimaryProfileCard';
import { ReactNode } from 'react';
import { Document } from '@contentful/rich-text-types';
import RichText from '../../../contentful-elements/RichText/RichText';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${spacing[9]}px;
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
  }
`;

const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]}px;
`;

const CarouselSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[5]}px;
`;

type Props = {
  sectionTitle?: string;
  title?: string;
  paragraph?: string;
  primaryProfilesHeadline?: string;
  secondaryProfilesHeadline?: string;
  primaryProfiles?: {
    name?: string;
    title?: string;
    description?: Document | string;
    imageUrl?: string;
    imageAlt?: string;
  }[];
  secondaryProfiles?: {
    name?: string;
    title?: string;
    imageUrl?: string;
    imageAlt?: string;
  }[];
  profileDescriptionOverride?: ReactNode;
  id?: string;
};

export default function HighlightedProfilesLayout({
  sectionTitle,
  title,
  paragraph,
  primaryProfilesHeadline,
  secondaryProfilesHeadline,
  primaryProfiles,
  secondaryProfiles,
  profileDescriptionOverride,
  id,
}: Props) {
  if (
    !sectionTitle &&
    !title &&
    !paragraph &&
    !primaryProfiles?.length &&
    !secondaryProfiles?.length
  )
    return null;

  return (
    <Container id={id}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper>
        {(title || paragraph) && (
          <IntroSection>
            {title && <Typography variant="h3">{title}</Typography>}

            {paragraph && <Typography variant="body">{paragraph}</Typography>}
          </IntroSection>
        )}

        {!!primaryProfiles?.length && (
          <CarouselSection>
            {primaryProfilesHeadline && (
              <Typography variant="overline" color={colors.blackSecondary}>
                {primaryProfilesHeadline}
              </Typography>
            )}

            <Carousel>
              {primaryProfiles?.map((primaryProfile) => (
                <PrimaryProfileCard
                  key={primaryProfile.name}
                  name={primaryProfile.name}
                  title={primaryProfile.title}
                  description={
                    profileDescriptionOverride ??
                    (typeof primaryProfile.description === 'string' ? (
                      <Typography color={colors.blackSecondary}>
                        {primaryProfile.description}
                      </Typography>
                    ) : (
                      <RichText
                        document={primaryProfile.description}
                        textColor={colors.blackSecondary}
                        skipWrapper
                      />
                    ))
                  }
                  imageUrl={primaryProfile.imageUrl}
                  imageAlt={primaryProfile.imageAlt}
                />
              ))}
            </Carousel>
          </CarouselSection>
        )}

        {!!secondaryProfiles?.length && (
          <CarouselSection>
            {secondaryProfilesHeadline && (
              <Typography variant="overline" color={colors.blackSecondary}>
                {secondaryProfilesHeadline}
              </Typography>
            )}

            <Carousel differentNrOfSlidesPerBreakpoint>
              {secondaryProfiles?.map((secondaryProfile) => (
                <SecondaryProfileCard
                  key={secondaryProfile.name}
                  name={secondaryProfile.name}
                  title={secondaryProfile.title}
                  imageUrl={secondaryProfile.imageUrl}
                  imageAlt={secondaryProfile.imageAlt}
                />
              ))}
            </Carousel>
          </CarouselSection>
        )}
      </ContentWrapper>
    </Container>
  );
}
