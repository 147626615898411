/* eslint-disable react/display-name */
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Document, Inline, INLINES, Text } from '@contentful/rich-text-types';
import Image from 'next/image';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Entry, EntrySkeletonType } from 'contentful';
import Link from 'next/link';
import { memo, ReactNode, useContext } from 'react';
import { isTypeScribble } from '../../../../@types/generated';
import { colors, spacing } from '../../../utils/styleguide';
import Icon from '../../component-library/Icon';
import StaticPageLink from '../../component-library/navigation/LegacyStaticPageLink';
import Scribble, { DecorationVariants } from '../../component-library/text/Scribble';
import Typography from '../../component-library/text/Typography';
import { TemplatingContext } from '../contexts/TemplatingContext';

export const ImageWrapper = styled.div`
  margin: ${spacing[4]}px 0;
  position: relative;
  width: 100%;
`;

type StyledLinkProps = { linkColor: string | undefined; underlineLinks: boolean | undefined };

const StyledLink = styled.a`
  color: ${(props: StyledLinkProps) => props.linkColor};
  text-decoration: ${(props: StyledLinkProps) => (props.underlineLinks ? 'underline' : 'none')};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing[2]}px;
  flex-shrink: 0;
`;

const bottomMarginCss = css`
  margin-bottom: ${spacing[3]}px;
`;

type RenderParams = {
  textColor?: string;
  useBuiltInMargins?: boolean;
  withCheckmark?: boolean;
  renderText?: (text: string) => ReactNode;
  heading2TagOverride?: string;
  linkColor?: string;
  underlineLinks?: boolean;
  renderEntry?: <T extends EntrySkeletonType>(entry: Entry<T>) => ReactNode;
};

const generateRenderOptions = ({
  textColor,
  useBuiltInMargins,
  withCheckmark,
  renderText = (text) => text,
  linkColor,
  underlineLinks,
  renderEntry,
}: RenderParams): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        variant="body"
        css={bottomMarginCss}
        color={textColor}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography
        variant="h1"
        color={textColor}
        css={bottomMarginCss}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography
        variant="h2"
        color={textColor}
        css={bottomMarginCss}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography
        css={bottomMarginCss}
        variant="h3"
        color={textColor}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography
        css={bottomMarginCss}
        variant="h4"
        color={textColor}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography
        variant="h5"
        css={bottomMarginCss}
        color={textColor}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        variant="h6"
        css={bottomMarginCss}
        color={textColor}
        useBuiltInMargins={useBuiltInMargins}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        css={[
          withCheckmark &&
            css`
              padding-inline-start: ${spacing[3]}px;
              display: flex;
              flex-direction: column;
              gap: ${spacing[3]}px;
            `,
          bottomMarginCss,
        ]}
      >
        {children}
      </ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li
        css={[
          withCheckmark &&
            css`
              display: flex;
              align-items: center;
              list-style-type: none;
            `,
        ]}
      >
        {withCheckmark && <StyledIcon name="check-filled" fill={colors.black} size={20} />}
        {children}
      </li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, title } = node.data.target.fields;
      const { contentType, url, details } = file;
      if (contentType.startsWith('image/')) {
        const landscape = details?.image?.width > details?.image?.height;

        return (
          <ImageWrapper>
            <Image
              src={`https:${url}`}
              alt={title}
              height={details?.image?.height}
              width={details?.image?.width}
              style={
                landscape
                  ? { width: '100%', height: 'auto' }
                  : { width: 'auto', height: '100%', maxHeight: '50vh' }
              }
              sizes="100vw, (min-width: 768px) 100vw, (max-width: 1200px) 100vw"
            />
          </ImageWrapper>
        );
      } else {
        return (
          <Typography
            tag="a"
            href={`https:${url}`}
            target="_blank"
            rel="noreferrer noopener"
            download
            useBuiltInMargins={useBuiltInMargins}
          >
            <Icon
              name="file-text"
              css={css`
                position: relative;
                top: 2px;
                margin-right: ${spacing[1]}px;
              `}
            />
            {title}
          </Typography>
        );
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return handleEntry({
        entry: node.data.target,
        color: textColor,
        linkColor: linkColor,
        underlineLinks: underlineLinks,
        renderEntry,
      });
    },
    [INLINES.ENTRY_HYPERLINK]: (node) => {
      const { slug, title } = node.data.target.fields;
      return StaticPageLink({ slug, title });
    },
    [INLINES.HYPERLINK]: (node) => {
      const isLocalLink = node.data.uri && node.data.uri.startsWith('/');
      const content = (node.content[0] as Text).value;
      return (
        <span
          css={css`
            > * {
              color: ${textColor ?? colors.blackPrimary};

              :hover {
                color: ${colors.purple400};
              }
            }
          `}
        >
          {isLocalLink ? (
            <Link href={node.data.uri} legacyBehavior>
              <StyledLink linkColor={linkColor} underlineLinks={underlineLinks}>
                {content}
              </StyledLink>
            </Link>
          ) : (
            <a href={node.data.uri}>{content}</a>
          )}
        </span>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      return handleEntry({
        entry: node.data.target,
        isInline: true,
        color: textColor,
        linkColor: linkColor,
        underlineLinks: underlineLinks,
        renderEntry,
      });
    },
    [BLOCKS.QUOTE]: (node) => {
      return (
        <div
          style={{
            borderLeft: `2px solid ${colors.purple400}`,
            marginTop: `${spacing[3]}px`,
            marginBottom: `${spacing[4]}px`,
            padding: `${spacing[1]}px ${spacing[3]}px`,
          }}
        >
          {node.content.map((paragraphNode, index) => {
            return (
              <div key={index} style={{ marginTop: index > 0 ? 2 : 0 }}>
                {(paragraphNode as Block | Inline).content.map((contentNode, index) => {
                  const marks = (contentNode as Text).marks;
                  const isBold = marks?.map((mark) => mark.type).includes('bold');
                  const isItalic = marks?.map((mark) => mark.type).includes('italic');
                  if (contentNode.nodeType === 'text') {
                    const value = (contentNode as Text).value;

                    return (
                      <Typography
                        key={index}
                        variant="h6"
                        style={{
                          display: 'inline',
                          fontWeight: isBold ? 500 : 300,
                          fontStyle: isItalic ? 'italic' : 'normal',
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  }

                  if (contentNode.nodeType === 'hyperlink') {
                    const isLocalLink =
                      contentNode.data.uri && contentNode.data.uri.startsWith('/');
                    const content = (contentNode.content[0] as Text).value;

                    return isLocalLink ? (
                      <Link key={index} href={contentNode.data.uri} passHref legacyBehavior>
                        <Typography
                          tag="a"
                          variant="h6"
                          hoverColor={colors.purple400}
                          style={{
                            display: 'inline',
                            fontWeight: isBold ? 500 : 300,
                            fontStyle: isItalic ? 'italic' : 'normal',
                            textDecoration: 'underline',
                          }}
                        >
                          {content}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        key={index}
                        tag="a"
                        variant="h6"
                        hoverColor={colors.purple400}
                        href={contentNode.data.uri}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{
                          display: 'inline',
                          fontWeight: isBold ? 500 : 300,
                          fontStyle: isItalic ? 'italic' : 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        {content}
                      </Typography>
                    );
                  }

                  const renderOptions = generateRenderOptions({
                    textColor,
                    useBuiltInMargins,
                    withCheckmark,
                    renderText,
                    linkColor,
                    underlineLinks,
                    renderEntry,
                  });
                  const renderType = renderOptions.renderNode?.[contentNode.nodeType];
                  if (renderType) {
                    return renderType(contentNode, null);
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    },
  },
  renderText(text) {
    if (renderText) return renderText(text);

    return text;
  },
});

function handleEntry<T extends EntrySkeletonType>({
  entry,
  isInline,
  color,
  linkColor,
  underlineLinks,
  renderEntry,
}: {
  entry?: Entry<T, 'WITHOUT_UNRESOLVABLE_LINKS'>;
  isInline?: boolean;
  color?: string;
  linkColor?: string;
  underlineLinks?: boolean;
  renderEntry?: (entry: Entry<T>) => ReactNode;
}) {
  if (!entry) {
    return;
  }
  if (isTypeScribble(entry)) {
    const { text, richText, color: scribbleColor, variant, decoration } = entry.fields;
    if (!text && !richText) {
      return null;
    }
    const appliedColor = (scribbleColor && colors[scribbleColor]) || color;
    const component = richText ? (
      <RichText
        document={
          'nodeType' in richText && richText.nodeType === 'document'
            ? (richText as Document)
            : undefined
        }
        skipWrapper
        textColor={appliedColor}
        linkColor={linkColor}
        underlineLinks={underlineLinks}
      />
    ) : (
      (text as string)
    );
    return (
      <Scribble
        variant={variant}
        decoration={decoration as DecorationVariants}
        tag={isInline ? 'span' : undefined}
        color={appliedColor}
      >
        {component}
      </Scribble>
    );
  }

  if (renderEntry) {
    return renderEntry(entry);
  }
}

export type RichTextProps = {
  document?: Document;
  centered?: boolean;
  textColor?: string;
  className?: string;
  skipWrapper?: boolean;
  useBuiltInMargins?: boolean;
  withCheckmark?: boolean;
  linkColor?: string;
  underlineLinks?: boolean;
  renderEntry?: <T extends EntrySkeletonType>(entry: Entry<T>) => ReactNode;
  isFaq?: boolean;
};

const RichText = ({
  document,
  centered,
  textColor,
  className,
  skipWrapper,
  useBuiltInMargins,
  withCheckmark,
  linkColor,
  underlineLinks,
  renderEntry,
  isFaq,
}: RichTextProps) => {
  const { templateReplacer } = useContext(TemplatingContext);
  if (!document) {
    return null;
  }
  const renderOptions = generateRenderOptions({
    textColor,
    useBuiltInMargins,
    withCheckmark,
    renderText: templateReplacer,
    linkColor,
    underlineLinks,
    renderEntry,
  });

  const documentElement = documentToReactComponents(document, renderOptions);
  if (skipWrapper) {
    return documentElement as JSX.Element;
  }
  return (
    <Typography
      tag="div"
      itemProp={isFaq ? 'text' : undefined} // used for SEO purposes
      className={className}
      css={css`
        position: relative;
        z-index: 0;
        white-space: pre-wrap;
        text-align: ${centered ? 'center' : 'initial'};
        color: ${textColor};
        br:last-child {
          display: none;
        }

        p:last-child {
          margin-bottom: 0;
        }
      `} // https://github.com/contentful/rich-text/issues/101
      useBuiltInMargins={useBuiltInMargins}
    >
      {documentElement}
    </Typography>
  );
};

export default memo(RichText);
